// insert status
window.STATUS = {
  opened: false,
  scrollTop: 0,
  fixed: function(status) {
    const $body = document.getElementsByTagName("body")[0]

    if (status) {
      window.STATUS.opened = status
    }
    if (window.STATUS.opened === false) {
      window.STATUS.scrollTop = window.scrollY

      $body.classList.add("fixed")
      $body.style.top = -window.STATUS.scrollTop

      window.STATUS.opened = true
    } else {
      $body.classList.remove("fixed")
      $body.style.top = "0"

      window.scrollTo(0, window.STATUS.scrollTop)

      window.STATUS.opened = false
    }
  }
}

// hbm
const btnMenu = document.querySelector(".js-btn-menu")

btnMenu.addEventListener("touchstart", (event) => {
  const searchBoxOuter = document.querySelector(".js-search-box-outer")
  if (searchBoxOuter.classList.contains("show")) {
    searchBoxOuter.classList.remove("show")
  }

  const notificationOuter = document.querySelector(".js-notification-outer")
  if (notificationOuter.classList.contains("show")) {
    notificationOuter.classList.remove("show")
    window.STATUS.fixed(true)
  }

  const menu = document.querySelector(".js-menu")
  const faMenu = document.querySelector(".js-fa-menu")
  const faClose = document.querySelector(".js-fa-close")
  menu.classList.toggle("show")
  faMenu.classList.toggle("show")
  faClose.classList.toggle("show")
}, false)

$('.header2').on('click', function() {
  let menuList = $(this).next('.hide-default');
  menuList.toggleClass('show');
  menuList.slideToggle(200);
  if(menuList.hasClass('show')) {
    $(this).children('.arrow').css('transform', 'rotate(90deg)');
  } else {
    $(this).children('.arrow').css('transform', '');
  }
});

// search
const btnSearch = document.querySelector(".js-btn-search")

btnSearch.addEventListener("touchstart", (event) => {
  const menu = document.querySelector(".js-menu")
  if (menu.classList.contains("show")) {
    menu.classList.remove("show")
  }

  const faMenu = document.querySelector(".js-fa-menu")
  const faClose = document.querySelector(".js-fa-close")
  if (faClose.classList.contains("show")) {
    faClose.classList.remove("show")
    faMenu.classList.add("show")
  }

  const notificationOuter = document.querySelector(".js-notification-outer")
  if (notificationOuter.classList.contains("show")) {
    notificationOuter.classList.remove("show")
    window.STATUS.fixed(true)
  }

  const searchBoxOuter = document.querySelector(".js-search-box-outer")
  searchBoxOuter.classList.toggle("show")
}, false)

// overlay
const overlay = document.querySelector(".js-search-box-outer.js-overlay")

overlay.addEventListener("click", (e) => {
  const searchBox = document.querySelector(".js-search-box")

  let classValue = ""

  for (let i = 0; i < e.target.classList.length - 1; i++) {
    classValue += `.${e.target.classList[i]}`
  }

  if (classValue) {
    if (!searchBox.querySelector(classValue)) {
      const searchBoxOuter = document.querySelector(".js-search-box-outer")
      searchBoxOuter.classList.remove("show")
    }
  }

  e.stopPropagation()
})
